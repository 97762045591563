import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import '@fortawesome/fontawesome-free/css/all.min.css'
import Menu from './components/modules/Menu';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './components/pages/Home';
import Nosotros from './components/pages/Nosotros';
import Contactenos from './components/pages/Contactenos';
import { useEffect } from 'react';
import Footer from './components/modules/Footer';

function App() {

  
  useEffect(()=>{document.title="Innova Express"});


  return (
    <div className="container">
      <BrowserRouter>      
      <Menu></Menu>
      <Switch>
        <Route exact path="/"><Home/></Route>
        <Route exact path="/nosotros"> <Nosotros/></Route>
        <Route exact path="/contactenos"> <Contactenos/></Route>


      </Switch>
      
      </BrowserRouter>
      
        <div className="col-12" >
            <Footer></Footer>
        </div>

    </div>
  );
}

export default App;
