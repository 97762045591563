import Mensajero from './../img/mensajero.png'



export default function SiderA(){
    return(
        <div  className="me-12 d-none d-lg-block mt-1"  >
          
          <p style={{textAlign:'justify'}}>
          <img className='d-block w-100' src={Mensajero} alt='Mensajero' />
          </p>
          
        </div>
        
    )
}