export default function Footer(){

return(
<div>
    
<footer className="text-center text-lg-start bg-light text-muted">
    
  <section className="d-flex justify-content-center  p-4 border-bottom">
  
  
    <div className="me-5 d-none d-lg-block">
      <span>Siguenos en nuestras redes sociales:</span>
    </div>
    
    <div>
      <a href="https://www.facebook.com/mrepartoexpress/" target="_blank" className="me-4 text-reset">
        <i className="fab fa-facebook-f"></i>
      </a>
      <a href="https://www.instagram.com/innova_express" target="_blank"  className="me-4 text-reset">
        <i className="fab fa-instagram"></i>
      </a>
    </div>
    
  </section>
  
  <section className="">
    <div className="container text-center text-md-start mt-5">
      <div className="row mt-3">
        
        <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mb-4">
          <h6 className="text-uppercase fw-bold mb-4">
            <i className="fas fa-gem me-3"></i>Innova Express Multiservicios SAS
          </h6>
          <p>
            Innova Express es una compañia comprometida con nuestros clientes y el desarrollo economico y social de la región.
          </p>
          <p>NIT: 9006842740</p>
        </div>
        

        
        <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mb-4" style={{textAlign:'center'}} >
          <h6 className="text-uppercase fw-bold mb-4">
            Servicios
          </h6>
          <p>
            <a href="#!" className="text-reset">Mensajeria</a>
          </p>
          <p>
            <a href="#!" className="text-reset">Entrega de facturas</a>
          </p>
          <p>
            <a href="#!" className="text-reset">Domicilios</a>
          </p>
          <p>
            <a href="#!" className="text-reset">Cobros Juridicos</a>
          </p>
        </div>
        

        

        
        <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mb-md-0 mb-4"  style={{textAlign:'center'}} >
            
          <h6 className="text-uppercase fw-bold mb-4">Contactenos</h6>
          <p><i className="fas fa-home me-3"></i>Dirección: Cll 25 Kr 6 24 - Santa Marta</p>
          <p>
            <i className="fas fa-envelope me-3"></i>
            innovaexpressmultiservicios@gmail.com
          </p>
          <p><i className="fas fa-mobile me-3"></i>+57 304 605 0316</p>
          <p><i className="fas fa-phone me-3"></i>(605) 4330864</p>
        </div>
        
      </div>
      
    </div>
  </section>
  


  <div className="text-center p-4" style={{backgroundColor:'rgba(0, 0, 0, 0.05)'}}>
    © 2022 Copyright:
    <a className="text-reset fw-bold" href="https://multinet.com.co/">Multinet Soluciones Digitales</a>
  </div>
</footer>
</div>


)


}