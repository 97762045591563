import Footer from '../modules/Footer';
import MapaFormulario from '../mapaLeaflet/MapaFormulario';
import { CoordenadaDTO } from '../mapaLeaflet/Coordenada.model';
import Servicios from '../modules/Servicios';
import FormularioContacto from '../modules/FormularioContacto';


export default function Contactenos() {

    


    const coordenadas: CoordenadaDTO[] = [{ lat: 11.2379455, lng: -74.207022 }];

    return (
        <div className="bg-light">


            <div className="col-12" >
                <FormularioContacto></FormularioContacto>
            </div>

            <div className="container"><hr></hr></div>
            <div className="col-12" >
                <MapaFormulario campoLat='latitud' campoLng='longitud' coordenadas={coordenadas} />
            </div>


        </div>


    )

}