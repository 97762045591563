import { CoordenadaDTO } from './Coordenada.model';
import Mapa from './Mapa';
export default function MapaFormulario(props: mapaFormularioProps) {



    function actualizarCampos(coordenadas: CoordenadaDTO) {
        console.log("actualizo el campo");

    }


    return (
        <section className='w-75 mx-auto text-center pt-5'>
            <h3 className='p-3 fs-4 border-top border-3 '>
                Nuestra Sede - Santa Marta
            </h3>
            <div>
                <Mapa coordenadas={props.coordenadas}   ></Mapa>
            </div>
        </section>);
}

interface mapaFormularioProps {
    coordenadas: CoordenadaDTO[];
    campoLat: string;
    campoLng: string;
}

MapaFormulario.defaultProps = {
    coordenadas: []
}