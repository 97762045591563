import Slider_1 from './../img/sliderhome/slider1.jpg'
import Slider_2 from './../img/sliderhome/slider2.jpg'
import Slider_3 from './../img/sliderhome/slider3.jpg'
import Slider_4 from './../img/sliderhome/slider4.jpg'

import Carousel from 'react-bootstrap/Carousel';

export default function SliderHome() {
    return (
        <>


            <Carousel interval={3500}>
                    <Carousel.Item>
                        <img className='d-block w-100' src={Slider_1} alt='First slide' />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='d-block w-100' src={Slider_2} alt='Third slide' />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='d-block w-100' src={Slider_4} alt='Third slide' />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='d-block w-100' src={Slider_3} alt='Third slide' />
                    </Carousel.Item>
                </Carousel>
        </>
    )
}