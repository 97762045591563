
import { Parallax } from 'react-parallax'
import Sierra from './../img/sierra.jpg'

import CSS from './../css/parallax.module.css'

export default function ParallaxUno(){

  
    return(
    <div className='mt-2'>
        <Parallax bgImage={Sierra} strength={200}>
            <div style={{height:'300px'}}>
                <div className={CSS.insideStyle}>
                    <h3 style={{color:'#000',textAlign:'center'}}>INNOVA EXPRESS MULTISERVICIOS</h3>
                    <p style={{color:'#000',fontSize:'20px',textAlign:'center'}}>Innova Express, es una compañía comprometida con nuestros clientes y el desarrollo económico y social de la región. </p>
                </div>
            </div>
        </Parallax>
    </div>
        )
}