

export default function FormularioContacto() {
    return (
        <div className="row">
            <div className='col-12 col-sm-4 mt-2'>
                <img className="card-img-top" src={require('./../img/contacto.png')} alt="Card image cap" />
            </div>
            <div className='col-12 col-sm-8 p-4 '>
                <h6 className="text-uppercase fw-bold mb-4">Contactenos</h6>
                <p><i className="fas fa-home me-3"></i>Cll 25 Kr 6 24 - Santa Marta</p>
                <p><i className="fas fa-envelope me-3"></i>innovaexpressmultiservicios@gmail.com - directorcomercial@innovaexpress.co</p>
                <p><i className="fas fa-phone me-3"></i>+57 304 605 0316</p>
                <p><i className="fas fa-phone me-3"></i>(605) 4330864</p>
            </div>
        </div>
    )
}