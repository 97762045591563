export default function DescripcionSitio(){
    return(

        <section className="pt-2">
            <div className="container-fluid pt-10 text-center">
            <h3 className="fs-5">Innova Express Multiservicios</h3>
                    <p >Innova Express, es una compañía comprometida con nuestros clientes y el desarrollo económico y social de la región. </p>              
     
            </div>

            
        </section>



    )
}