
import Mensjeria from './../img/servicios/mensajeria.jpg'
import Logistica from './../img/servicios/logistica.jpg'
import VCHoras from './../img/servicios/24horas.jpg'


export default function Servicios(){
    return(
        <section className="container-fluid mx-auto text-center pt-2">


            <div>
            <h3 className='p-3 fs-3 border-top border-3 '>
                Nuestros Servicios
            </h3>
            </div>


            <div className='row w-75 mx-auto'>
                
                <div className='col-lg-4 col-md-12 col-sm-12'>                    
                    <img className='d-block w-100' src={Mensjeria} alt='Mensajero' />
                    <h5 className='text-center fs-5 mt-4 px-4 pb-1'>Mensajeria</h5>
                    <p className='px-4 text-center'>Somos una empresa con 9 años de experiencia en mensajeria urbana y rural.</p>
                </div>   
                
                
                <div className='col-lg-4 col-md-12 col-sm-12'>                    
                    <img className='d-block w-100' src={VCHoras} alt='24 Horas' />
                    <h5 className='text-center fs-5 mt-4 px-4 pb-1'>24 Horas</h5>
                    <p className='px-4 text-center'>Prestamos el servicio de entrega en 24 horas, con completa responsabilidad.</p>
                </div>   
                
                <div className='col-lg-4 col-md-12 col-sm-12'>                    
                    <img className='d-block w-100' src={Logistica} alt='24 Horas' />
                    <h5 className='text-center fs-5 mt-4 px-4 pb-1'>Logistica</h5>
                    <p className='px-4 text-center'>Somos profesionales en temas logisticos.</p>
                </div>   

            </div>
        </section>
    )
}