import { MapContainer ,TileLayer,Marker,useMapEvent, Popup} from "react-leaflet";
import L from 'leaflet'
import icon from 'leaflet/dist/images/marker-icon.png'
import iconshadow from 'leaflet/dist/images/marker-shadow.png'
import 'leaflet/dist/leaflet.css'
import { CoordenadaDTO } from './Coordenada.model';
import { useState } from 'react';
import Sede from './../img/sede.jpg'

export default function Mapa(props:mapaProps){

    const[coordenadas,SetCoordenadas]=useState<CoordenadaDTO[]>(props.coordenadas);

    let defaultIcon = L.icon({
        iconUrl:icon,
        shadowUrl:iconshadow,
        iconAnchor:[16,37]
    });

    L.Marker.prototype.options.icon = defaultIcon;
    

 return(

    <MapContainer scrollWheelZoom={false}
        center={[11.2379455, -74.207022]} zoom={16}
        style={{height:props.height}}
    >
            <TileLayer  
            attribution="Innova Express Multiservicios" 
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

        {coordenadas.map(coordenada=><Marcador  key={coordenada.lat+coordenada.lng} 
                        {...coordenada} />)}

            
    </MapContainer>
 )

}


interface mapaProps{
    height:string;
    coordenadas:CoordenadaDTO[];
}

Mapa.defaultProps={
    height:'500px'
}

function Marcador(props:CoordenadaDTO){
    return (
    <Marker 
        title="Innova Express" 
        position={[props.lat,props.lng]} 
        >
         <Popup>
            <h5>Innova Express</h5>
            <p>Dirección: Cll 25 Kr 6 24 - Santa Marta</p>    
            <img style={{width:'100%'}}  src={Sede} alt='sede' />        
         </Popup>    
        </Marker>)
}

