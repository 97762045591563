import SiderA from '../modules/SiderA';
import Footer from '../modules/Footer';
import Servicios from '../modules/Servicios';
export default function Nosotros() {

    return (
        <div className="bg-white">


            <div className="row bg-white">

                <div className="col-12 col-lg-9 " style={{ padding: '20px' }}>

                <p style={{textAlign:'justify'}}>
                        <h3>Nosotros</h3>
                        Innova Express Multiservicios da soporte en el logro de sus objetivos mediante la utilización de ideas innovadoras en la Gestión de Cobro de Cartera Vencida; así mismo, en el empleo de nuestros servicios como una extensión de su Departamento de Cartera y la rápida recuperación de ella. Utilizada oportunamente, accediendo al logro de economías en costos administrativos y financieros y mayor rentabilidad al reducir substancialmente el periodo de cobro de nuestros usuarios.
                    </p>


                    <p style={{textAlign:'justify'}}>
                        Somos empresa ligada en la experiencia de prestar servicio de gestión urbana y la recolección de registros e información basados en el deber con seriedad, cumplimiento y tecnología, pretendemos consolidarnos como líder que nos permita adaptarnos y mantenernos dentro del entorno como empresa eficaz y eficiente prestadora del servicio de <strong>GESTION DE COBRO</strong>
                    </p>

                    <p style={{textAlign:'justify'}}>
                    Con la responsabilidad y satisfacción de nuestros clientes para el reconocimiento de calidad y excelencia de nuestras gestiones, contando con un talento humano comprometido en el mejoramiento continuo, dentro de un entorno de permanente cambio y exigencia.
                    </p>

                    <p style={{textAlign:'justify'}}>
                        <h3>Mision</h3>
                    “satisfacer las necesidades de nuestros clientes, estando un paso adelante en sus expectativas; mediante la organización y calidad de nuestros servicios. Ayudándoles a alcanzar sus metas de negocio, proveyéndoles servicios y soluciones innovadoras.”
                    </p>
                    <p style={{textAlign:'justify'}}>
                        <h3>Vision</h3>
                    “Ser la empresa líder e innovadora en nuestros productos y servicios, dar soluciones a nuestros clientes. Ser reconocida por la calidad humana y profesional de nuestra gente”
                    </p>


                </div>

                <div className="col-12 col-lg-3 bg-white">
                    <SiderA></SiderA>
                </div>

            </div>

            <div className="col-12" >
                <Servicios></Servicios>
        </div>

        </div>


    )

}