import Logo from './../img/logosmallblanco.png'
import { Link, NavLink } from 'react-router-dom';

export default function Menu(){

  const claseActiva = "active";

    return (
<>

<nav className="navbar navbar-expand-lg navbar-dark static-top" style={{ marginTop: '10px', backgroundColor: '#016add' }}  >
  <div className="container">
    <NavLink className="navbar-brand" to="/" activeClassName={claseActiva} >
      <img src={Logo} alt="..." height="50" />
    </NavLink>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto"  style={{fontSize:'18px',fontWeight:'500'}} >

        <li className="nav-item">
          <NavLink className="nav-link" to="/"   activeClassName={claseActiva}   >Inicio</NavLink>
        </li>

        <li className="nav-item">
          <NavLink className="nav-link "  to="/nosotros"  activeClassName={claseActiva} >Nosotros</NavLink>
        </li>
        
        <li className="nav-item">
          <NavLink className="nav-link "  to="/contactenos"  activeClassName={claseActiva} >Contactenos</NavLink>
        </li>





        <li className="nav-item dropdown">
          <NavLink className="nav-link dropdown-toggle" to="/servicios" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Servicios
          </NavLink>
          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
            <li><NavLink className="dropdown-item" to="/mensajeria"  activeClassName={claseActiva} >Mensajería</NavLink></li>
            <li><hr className="dropdown-divider" /></li>
            <li><NavLink className="dropdown-item" to="/cobros"  activeClassName={claseActiva} >Cobros</NavLink></li>
            <li><hr className="dropdown-divider" /></li>
            <li><NavLink className="dropdown-item" to="/otros"  activeClassName={claseActiva} >Otros</NavLink></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div className='container-fluid' style={{color:'#000', backgroundColor:'#e9ecef',height:'auto',padding:'5px'}} >
 Celular: +57 304 605 0316 - Dirección: Cll 25 Kr 6 24 - Santa Marta
</div>
</>


    )

}