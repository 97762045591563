import React from 'react'; 
import MapaFormulario from "../mapaLeaflet/MapaFormulario";
import SliderHome from "../modules/SliderHome";
import { CoordenadaDTO } from "../mapaLeaflet/Coordenada.model";
import Footer from "../modules/Footer";
import SiderA from "../modules/SiderA";
import ParallaxUno from "../modules/ParallaxUno";
import Servicios from "../modules/Servicios";
import DescripcionSitio from "../modules/DescripcionSitio";

export default function Home() {


    //11.237057, -74.209588
    const coordenadas: CoordenadaDTO[] = [{ lat:11.237057, lng: -74.209588 }];

    return (
    
        <div className="bg-white">


        <div  className="row">
        <div className="col-12 col-lg-12">
            <SliderHome></SliderHome>
        </div>


        </div>

        
        <div  className="me-12 d-none d-lg-block"   >
                <ParallaxUno></ParallaxUno>
        </div>

        <div  className="me-12  d-lg-none"   >
            <DescripcionSitio></DescripcionSitio>
        </div>

        
        <div className="col-12" >
                <Servicios></Servicios>
        </div>

        
        <div className="col-12" >
            <MapaFormulario campoLat='latitud' campoLng='longitud' coordenadas={coordenadas} />
        </div>


    </div>
    
    )

}